import dynamic from 'next/dynamic';

import AnimatedLayout from 'components/organisms/Layout/AnimatedLayout';
import { SiteLayout } from 'components';
import BottomNavMenu from 'v2/component/common/bottom-nav';

// 비대면 진료 메인 페이지
// const PhilingMainTemplate = dynamic(() => import('components/templates/PhilingMainTemplate'));
const PhilingMainTemplate = dynamic(() => import('./home/testMain'));

// 새로운 비대면 메인
export default function Main() {
  return (
    <SiteLayout>
      <AnimatedLayout>
        <PhilingMainTemplate />
      </AnimatedLayout>
      <BottomNavMenu />
    </SiteLayout>
  );
}
